import NumberFormatComp from "@Components/Common/NumberFormatComp";
import _ from "lodash";
import { useRouter } from "next/router";
import { RiAddFill } from "react-icons/ri";

const QunatitySection = (props) => {
  const { onChange, value, disabled } = props;
  return (
    <div id="qty">
      <button
        type="button"
        id="1"
        className="sub"
        value={value}
        disabled={disabled}
        onClick={(e) => onChange(e, "sub")}
      >
        -
      </button>
      <input
        name="1"
        type="text"
        className="get"
        list="350"
        max="350"
        readOnly
        value={value}
      />
      <button
        type="button"
        id="1"
        className="add"
        value={value}
        disabled={disabled}
        onClick={(e) => onChange(e, "add")}
      >
        +
      </button>
    </div>
  );
};

const InnerHtml = ({ contents }) => {
  const router = useRouter();
  const {
    data,
    content,
    partOf,
    addToCart,
    setOpen,
    customSelectedData,
    setCustomeData,
    eligiableMsg,
    setEligiableMsg,
    minBV,
    setMinBv,
    totalAmount,
    setTotalAmount,
    quantityCheck,
    setQuantityCheck,
    section,
    totalWeight,
    setTotalWeight,
  } = contents;

  // provide total bonus Value of product which is selected
  function _totalBonus(data) {
    let bonusValues = 0;
    _.forEach(data, (row) => {
      let calculate = row["bonus_value"] * +row?.qty;
      bonusValues = bonusValues + +calculate;
    });
    return bonusValues;
  }

  //provided total weight of products
  function _totalWeightFunc(data) {
    let totalWeight = 0;
    _.forEach(data, (row) => {
      let calculate = row["weight"] * +row?.qty;
      totalWeight = totalWeight + +calculate;
    });
    return totalWeight;
  }

  // provide total amount of product which is selected
  function _totalAmount(data) {
    let totalAmount = 0;
    _.forEach(data, (row) => {
      let calculate = row["autoship_cost_price"] * +row?.qty;
      totalAmount = totalAmount + +calculate;
    });
    return totalAmount;
  }

  // message sectin if customer reach min bonus value
  function _checkTotalBonus(data) {
    const totalBonus = _totalBonus(data);
    let currentBV = 150 - totalBonus <= 0 ? 0 : 150 - totalBonus;
    setMinBv(currentBV);
    if (totalBonus >= 150) {
      setEligiableMsg("Your Custom Build Promoter pack is ready");
    } else {
      setEligiableMsg(null);
    }
  }

  function commonDataAndQuantity(value, quantity) {
    const rejectedData = _.reject(customSelectedData, value);
    let values = {
      id: value?.id,
      product_name: value?.product_name,
      qty: 1,
      weight: value?.weight,
      bonus_value: value?.bonus_value,
      autoship_cost_price: value?.autoship_cost_price,
    };
    const getQTY = _.find(quantity, { id: value?.id });
    if (getQTY) {
      values = { ...values, ...{ qty: getQTY ? getQTY?.qty : 1 } };
    }
    let data =
      customSelectedData?.length > 0 ? [...rejectedData, values] : [values];
    setTotalAmount(_totalAmount(data));
    _checkTotalBonus(data);
    setTotalWeight(_totalWeightFunc(data));
    setCustomeData(data);
  }

  // selected data set in state
  function _selectedDataFunc(value) {
    const presence = _.find(customSelectedData, { id: value?.id });
    if (presence) {
      const rejectedData = _.reject(customSelectedData, presence);
      _checkTotalBonus(rejectedData);
      setTotalAmount(_totalAmount(rejectedData));
      setCustomeData(rejectedData);
    } else {
      value["product_name"] = value?.name;
      commonDataAndQuantity(value, quantityCheck);
    }
  }

  const _QuantitySection = (e, section, producData) => {
    let a = e?.currentTarget?.value;
    let max = producData?.quantity;
    let currentProduct = _.find(customSelectedData, { id: producData?.id });
    let values = [{}];

    if (section === "add") {
      if (+max > 0 && +a < +max) {
        let data = _.find(quantityCheck, { id: producData?.id });
        if (data) {
          let rejectData = _.reject(quantityCheck, data);
          values = [...rejectData, { id: producData?.id, qty: data?.qty + 1 }];
          if (currentProduct) {
            commonDataAndQuantity(currentProduct, values);
          }
        } else {
          values = [...quantityCheck, { id: producData?.id, qty: +a + 1 }];
          if (currentProduct) {
            commonDataAndQuantity(currentProduct, values);
          }
        }
      }
      setQuantityCheck(values);
    }

    if (section === "sub") {
      if (a > 1) {
        let data = _.find(quantityCheck, { id: producData?.id });
        if (data) {
          let rejectData = _.reject(quantityCheck, data);
          values = [...rejectData, { id: producData?.id, qty: data?.qty - 1 }];
          if (currentProduct) {
            commonDataAndQuantity(currentProduct, values);
          }
        } else {
          values = [...rejectData, { id: producData?.id, qty: +a - 1 }];
          if (currentProduct) {
            commonDataAndQuantity(currentProduct, values);
          }
        }
        setQuantityCheck(values);
      } else {
        setQuantityCheck(quantityCheck);
      }
    }
  };

  //set all selected custom product on localstorage
  function _storeDataOnLocal() {
    localStorage.setItem(
      "packProduct",
      JSON.stringify({
        customPack: {
          totalAmount: totalAmount,
          products: customSelectedData,
          totalBV: minBV,
          eligiable: eligiableMsg,
          quantityData: quantityCheck,
          totalWeight: totalWeight,
        },
      })
    );
    if (section === "viewCart") {
      location.reload();
    } else if (section === "signup") {
      router.push("/us/login");
    } else {
      router.push("/us/cart/viewCart/");
    }
  }

  return (
    <>
      {data?.length > 0 ? (
        _.map(
          _.reject(data, { name: content === "normal" ? partOf : "" }),
          (row, index) => (
            <div className="promoter_pack_container" key={index + 1}>
              <div className="image_section">
                <img
                  src={
                    row?.product_images?.length > 0
                      ? process.env.API_URL + row.product_images[0]?.image
                      : "/images/no-image.jpg"
                  }
                />
                <h3>{row?.name}</h3>
              </div>
              <div className="content_section">
                {content === "normal" && (
                  <>
                    <div className="retail_section cost_class">
                      <b>Retail:</b>
                      <NumberFormatComp value={row?.cost_price} />
                    </div>
                    <QunatitySection
                      value={1}
                      onChange={(e, section) => console.log(section)}
                      disabled={false}
                    />
                    <button
                      className="add_cart"
                      onClick={() => addToCart(row?.id, 1)}
                    >
                      Add to Cart
                    </button>
                  </>
                )}
                {content === "custom" && (
                  <>
                    <div className="smartShip_section cost_class">
                      <b>SmartShip:</b>
                      <NumberFormatComp value={row?.autoship_cost_price} />
                    </div>
                    <QunatitySection
                      value={
                        _.find(quantityCheck, { id: row?.id })
                          ? _.find(quantityCheck, { id: row?.id })?.qty
                          : 1
                      }
                      onChange={(e, section) =>
                        _QuantitySection(e, section, row)
                      }
                      disabled={!!(row?.name === "MEMBERSHIP")}
                    />
                    <div className="checkbox_data">
                      <input
                        type="checkbox"
                        checked={!!_.find(customSelectedData, { id: row?.id })}
                        id={`vehicle${row?.id}`}
                        value={row?.id}
                        onChange={() => _selectedDataFunc(row)}
                        disabled={!!(row?.name === "MEMBERSHIP")}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        )
      ) : (
        <div>loading.......................</div>
      )}
      {((content === "normal" && section === "signup") ||
        (content === "normal" && section === "packsec")) && (
          <div className="promoter_pack_container">
            <button onClick={() => setOpen(true)}>
              <RiAddFill fontSize={30} />
              <span>Create Custom Pack</span>
            </button>
          </div>
        )}
      {content === "custom" && (
        <div className="add_to_custom_pack">
          <button
            disabled={!eligiableMsg}
            className={!eligiableMsg ? "disable_class" : "enable_class"}
            onClick={() => _storeDataOnLocal()}
          >
            Add Custom Pack
          </button>
        </div>
      )}
    </>
  );
};
InnerHtml.defaultProps = {
  contents: {
    addToCart: () => console.log("hello"),
    content: "normal",
    customSelectedData: [],
    data: [],
    eligiableMsg: "",
    minBV: 150,
    partOf: "",
    quantityCheck: [],
    section: "packsec",
    setCustomeData: () => console.log("hello"),
    setEligiableMsg: () => console.log("hello"),
    setMinBv: () => console.log("hello"),
    setOpen: () => console.log("hello"),
    setQuantityCheck: () => console.log("hello"),
    setTotalAmount: () => console.log("hello"),
    totalAmount: 0,
  },
};
export default InnerHtml;
