import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

export default function NumberFormatComp({ value, render }) {
    return (
        <NumberFormat
            value={parseFloat(value).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={value => render ? render(value) : <div> {value} USD </div>} />

    )
}

NumberFormatComp.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.any
    ]),
    render: PropTypes.func
}


